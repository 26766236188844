import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { User } from '../../_models/models';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  menu = Menu;
  currentUser: User;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { 
    this.currentUser = this.authenticationService.currentUserValue;
  }

 

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
        this.openTab(event.url)
      });
  }

  openTab(url){
    this.menu.find(tabList=>{
      if(tabList.children){
        const activeTab = tabList.children.find(item=>{
          return url.indexOf(item.routerLink)>=0
        })
        tabList.isCollapsed = !activeTab
      }
    })
  }

  logout(){
    this.authenticationService.logout();
  }
}

const Menu = [
  {
    title: 'Dashboard',
    icon: 'fa-tachometer-alt', 
    routerLink: '/'
  },
  {
    title: 'AR',
    icon: 'fa-cube',
    isCollapsed: true,
    children : [
      {
        title: 'AR by Cesium',
        routerLink: '/cesium'
      },
      {
        title: 'AR by VPS/Map',
        routerLink: '/map'
      },
      {
        title: 'Users Placed AR',
        routerLink: '/anchor'
      },
      {
        title: 'Asset Library',
        routerLink: '/asset'
      },
      {
        title: 'Asset Category',
        routerLink: '/category'
      },
      {
        title: 'Asset Ability',
        routerLink: '/asset-ability'
      },
      {
        title: 'Vertical Carousel',
        routerLink: '/vertical-carousel'
      },
    ]
  },
  {
    title: 'Content',
    icon: 'fa-pen-nib',
    isCollapsed: true,
    children : [
      // {
      //   title: 'FAQs',
      //   routerLink: '/faq'
      // },
      // {
      //   title: 'Home Carousels',
      //   routerLink: '/carousel'
      // },
      {
        title: 'Languages',
        routerLink: '/language'
      },
      {
        title: 'Translations',
        routerLink: '/language-version'
      },
      {
        title: 'Templates',
        routerLink: '/template'
      },
      {
        title: 'Review Board Type',
        routerLink: '/restaurant-type'
      },
      {
        title: 'Fake Data',
        routerLink: '/fake-data'
      },
      {
        title: 'Log',
        routerLink: '/log'
      },
      {
        title: 'Resources',
        routerLink: '/resource'
      },
      {
        title: 'App Config',
        routerLink: '/setting'
      },
      {
        title: 'Daily Data',
        routerLink: '/daily'
      },
      {
        title: 'Visitor Record',
        routerLink: '/visitor-record'
      },
      {
        title: 'Vertical Carousel Posted',
        routerLink: '/vc-posted'
      },
      {
        title: 'Tag',
        routerLink: '/tag'
      }
    ]
  },
  {
    title: 'Broadcast',
    icon: 'fa-microphone-lines',
    isCollapsed: true,
    children : [
      {
        title: 'Streamer',
        routerLink: '/streamer'
      },
      {
        title: 'Streamer Event',
        routerLink: '/streaming'
      },
    ]
  },
  {
    title: 'Interactive',
    icon: 'fa-trophy',
    isCollapsed: true,
    children : [
      {
        title: 'Task',
        routerLink: '/task'
      },
      {
        title: 'Whitelist',
        routerLink: '/whitelist'
      },
      {
        title: 'Coupon',
        routerLink: '/coupon'
      },
      {
        title: 'Treasure Hunt',
        routerLink: '/campaign'
      },
      {
        title: 'Tressure Hunt Wave',
        routerLink: '/wave'
      },
      {
        title: 'Treasure Hunt Portal',
        routerLink: '/portal'
      },
      {
        title: 'Reward',
        routerLink: '/reward'
      },
    ]
  },
  {
    title: 'Partner',
    icon: 'fa-handshake',
    isCollapsed: true,
    children : [
      {
        title: 'Partner',
        routerLink: '/developer'
      },
      {
        title: '3D Assets',
        routerLink: '/product'
      },
      {
        title: '3D Assets Version',
        routerLink: '/version'
      },
      {
        title: 'Mark Down',
        routerLink: '/mark-down'
      },
      {
        title: 'Income',
        routerLink: '/income'
      },
      {
        title: 'Stripe Settings',
        routerLink: '/stripe-setting'
      },
      {
        title: 'Contact us',
        routerLink: '/contact-us'
      },
    ]
  },
  {
    title: 'MMITA Users',
    icon: 'fa-user',
    isCollapsed: true,
    children : [
      {
        title: 'Users Management',
        routerLink: '/customer'
      },
      {
        title: 'Abuse report',
        routerLink: '/report'
      },
      {
        title: 'Send Offcial Message',
        routerLink: '/message'
      },
    ]
  },
]
