<form *ngIf="formGroup" class="point-info-wrapper" nz-form [formGroup]="formGroup" [nzLayout]="'vertical'">
  <section nz-row [nzGutter]="24" class="section-wrapper" >
    <div class="drawer-header">
      <div class="point-type-header">
        {{pointType}}
      </div>
      <ng-content select="[content]"></ng-content>
    </div>
    <div nz-col nzSpan="24" *ngIf="currentFocusOnInfo?.id">
      <nz-form-item>
        <nz-form-label nzFor="id" nzRequired>ID</nz-form-label>
        <nz-form-control>
          <input nz-input type="text" [value]="currentFocusOnInfo?.id" nzRequired disabled>
        </nz-form-control>
      </nz-form-item>
    </div>
  </section>
  <section class="section-wrapper" nz-row [nzGutter]="16">
    <h5 nz-col nzSpan="24" class="header">
      Model Settings
    </h5>
    <ng-container [ngSwitch]="pointType">
    <!-- marker -->
      <ng-container *ngSwitchCase="'Map Objects'" >
        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzFor="name">Name</nz-form-label>
            <nz-form-control>
              <input nz-input type="text" formControlName="name">
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="mmitaRewardId">Reward</nz-form-label>
            <nz-form-control nzErrorTip="Please input field">
              <nz-select formControlName="mmitaRewardId" nzShowSearch>
                  <nz-option *ngFor="let reward of rewards" [nzValue]="reward?.id" [nzLabel]="reward?.description"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <!-- Portal Marker -->
        <div *ngIf="currentFocusOnInfo?.campaignWavePortalId" nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzFor="campaignWavePortalId">Wave Portal Id</nz-form-label>
            <nz-form-control>
              <nz-select nzAllowClear formControlName="campaignWavePortalId" nzShowSearch>
                  <nz-option *ngFor="let portal of portalSource" [nzValue]="portal?.id" [nzLabel]="portal?.id"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div *ngIf="currentFocusOnInfo?.address" nz-col nzSpan="24">
          <nz-form-item>
            <nz-form-label nzFor="address">Address</nz-form-label>
            <nz-form-control>
              <input nz-input type="text" formControlName="address">
            </nz-form-control>
          </nz-form-item>
        </div>

      </ng-container>
    <!-- stage -->
    <ng-container *ngSwitchCase="'Geospatial Drop Objects'">
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzFor="isInactive">Active</nz-form-label>
          <nz-form-control>
            <nz-radio-group formControlName="isInactive">
              <label nz-radio [nzValue]="false">True</label>
              <label nz-radio [nzValue]="true">False</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="12" *ngIf="supportOcclusion">
        <nz-form-item>
          <nz-form-label nzFor="withOcclusionMesh">Invisible from behind</nz-form-label>
          <nz-form-control>
            <nz-switch formControlName="withOcclusionMesh"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzFor="height" nzRequired>Height</nz-form-label>
          <nz-form-control nzErrorTip="Please input field">
            <nz-input-number formControlName="height" class="mit-number-field"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzFor="stageType" nzRequired>Stage Type</nz-form-label>
          <nz-form-control>
            <nz-select formControlName="stageType" nzShowSearch  (ngModelChange)="changeStageType($event)">
                <nz-option *ngFor="let item of stageTypeList" [nzValue]="item" [nzLabel]="item"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <ng-container [ngSwitch]="formGroup.controls.stageType.value">
        <div *ngSwitchCase="'VerticalCarousel'" nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label nzFor="verticalCarouselId">Vertical Carousel ID</nz-form-label>
            <nz-form-control>
              <nz-select formControlName="verticalCarouselId" nzShowSearch>
                  <nz-option *ngFor="let item of vcSourceList" [nzValue]="item.id" [nzLabel]="item?.description"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div *ngSwitchCase="'ReviewBoard'" nz-col [nzSpan]="24">
          <label for="ReviewBoard">Review Board Place ID:</label>
            <button type="button" nz-button nzType="primary" class="select-preview-asset" (click)="showSearchDialog()">
              {{ formGroup.controls.reviewBoardPlaceId.value ? formGroup.controls.reviewBoardPlaceId.value : 'Selete ReviewBoard'}}              
            </button>
        </div>
        
        <div *ngSwitchCase="'Streamer'" nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label nzFor="email">Stream Event ID</nz-form-label>
            <nz-form-control>
              <nz-select nzShowSearch nzAllowClear formControlName="streamEventId" (ngModelChange)="changeStreamingEvent()">
                <nz-option *ngFor="let streamEvent of streamingEventSource" nzLabel="【{{streamEvent.streamer.fullName}}】: {{streamEvent.id}}" [nzValue]="streamEvent.id"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </ng-container>

      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzFor="description">Description</nz-form-label>
          <nz-form-control>
            <input nz-input type="text" formControlName="description">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="scale">Scale</nz-form-label>
          <nz-form-control nzErrorTip="Please have a value greater than zero">
            <nz-input-number formControlName="scale" class="mit-number-field"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="visibleRadius">Visible Radius</nz-form-label>
          <nz-form-control  nzErrorTip="Please have a value greater than zero">
            <nz-input-number formControlName="visibleRadius" class="mit-number-field"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzFor="isGeospatial">Geospatial</nz-form-label>
          <nz-form-control>
            <nz-switch formControlName="isGeospatial" (ngModelChange)="changeGeospatial($event)"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzFor="lookAtUser">Look At User</nz-form-label>
          <nz-form-control>
            <nz-switch formControlName="lookAtUser"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>

      <ng-container *ngIf="!formGroup?.controls?.lookAtUser?.value">
        <div nz-row [nzGutter]="16" *ngIf="!!formGroup?.controls?.isGeospatial?.value;else showHeading">
          <div nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label nzFor="RotationX">Rotation X</nz-form-label>
              <nz-form-control>
                <nz-input-number formControlName="rotationX" class="mit-number-field"></nz-input-number>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label nzFor="RotationY">Rotation Y</nz-form-label>
              <nz-form-control>
                <nz-input-number formControlName="rotationY" class="mit-number-field"></nz-input-number>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label nzFor="RotationZ">Rotation Z</nz-form-label>
              <nz-form-control>
                <nz-input-number formControlName="rotationZ" class="mit-number-field"></nz-input-number>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <ng-template #showHeading>
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label nzRequired nzFor="RotationZ">Heading
                <i class="fa-solid fa-circle-exclamation icon" nz-tooltip [nzTooltipTitle]="tipContent"></i>
                <ng-template #tipContent>
                  <div style="text-align: center;">
                    0: North, 90: East, <br/>180: South, 270: West
                  </div>
                  </ng-template>
              </nz-form-label>
              <nz-form-control nzErrorTip="Please input field">
                <nz-input-number formControlName="heading" class="mit-number-field"></nz-input-number>
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-template>
      </ng-container>

      <nz-form-item *ngIf="currentFocusOnInfo?.address" nz-col nzSpan="24">
        <nz-form-label nzFor="address">Address</nz-form-label>
        <nz-form-control>
          <input nz-input type="text" formControlName="address">
        </nz-form-control>
      </nz-form-item>

      <div nz-col nzSpan="18">
        <nz-form-label nzFor="previewAssetId">Preview Asset ID</nz-form-label>
        <button *ngIf=" SupportPreviewAsset.indexOf(formGroup.value.stageType)>=0 ; else readOnlyId" type="button" nz-button nzType="primary" class="select-preview-asset" (click)="showAssetDialog(formGroup.value.stageType)">
          {{formGroup?.value?.previewAssetName || 'unset'}}
        </button>
        <ng-template #readOnlyId>
          <div>
            {{formGroup.value.previewAssetId}}
          </div>
        </ng-template>
      </div>

      <div nz-col nzSpan="6" *ngIf="formGroup.value.iconUrl">
        <label for="placeThumbnailUrl" class="form-label">Thumbnail</label>
        <div>
          <img class="icon-url" src="{{formGroup?.value?.iconUrl}}" alt="">
        </div>
      </div>

      <div nz-col nzSpan="24">
        <nz-form-label nzFor="previewAssetId">Environment Image</nz-form-label>
        <div *ngIf="previewMediaSource || formGroup.value.environmentPreviewImageUrl">
          <img [src]="previewMediaSource || formGroup.value.environmentPreviewImageUrl" class="thumbnail" alt="">
          <button *ngIf="formGroup.value.environmentPreviewImageUrl" nz-button nzType="primary" nzDanger (click)="removeEnvImg()">
            Remove Image Source
          </button>
        </div>
        <input
                style="margin-top: 5px;"
                id="file"
                type="file"
                class="form-control"
                accept="image/*"
                [multiple]="false"
                (change)="onFileChange($event, 0)"/>
        </div>
      </ng-container>

    <!-- portal -->
    <ng-container *ngSwitchCase="'Portal'">
      <div nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="campaignId">Campaign</nz-form-label>
          <nz-form-control nzErrorTip="Please input field">
            <nz-select nzShowSearch nzAllowClear formControlName="campaignId" (ngModelChange)="onChangeCampaign()">
              <nz-option *ngFor="let item of campaignSource" nzLabel="【{{item.name}}】: {{item.id}}" [nzValue]="item.id"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzRequired nzFor="campaignWaveId">Wave ID</nz-form-label>
          <nz-form-control nzErrorTip="Please input field">
            <nz-select nzShowSearch nzAllowClear formControlName="campaignWaveId">
              <nz-option *ngFor="let item of waveSource" [nzLabel]="item.id" [nzValue]="item.id"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="24">
        <button type="button" nz-button nzType="primary" (click)="createPortalMarker()" [disabled]="!currentFocusOnInfo.campaignWaveId">Create Marker Base On Portal</button>
      </div>
    </ng-container>
    </ng-container>
  </section>


  <section class="section-wrapper">
    <h5 class="header">
      Location Information
    </h5>
    <div nz-row [nzGutter]="16">
      <div nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="latitude">Latitude</nz-form-label>
          <nz-form-control nzErrorTip="Please input field">
            <nz-input-number formControlName="latitude" class="mit-number-field"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired nzFor="longitude">Longitude</nz-form-label>
          <nz-form-control nzErrorTip="Please input field">
            <nz-input-number formControlName="longitude" class="mit-number-field"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="pointType === 'Geospatial Drop Objects'">
          <nz-form-label nzRequired nzFor="altitude">Altitude</nz-form-label>
          <nz-form-control nzErrorTip="Please input field">
            <nz-input-number formControlName="altitude" class="mit-number-field"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </section>

  <div class="footer-btn-wrapper">
    <button type="button" nz-button nzType="primary" class="btn"  (click)="save()" >Save</button>
    <button *ngIf="editMode" type="button" nz-button nzType="primary" (click)="showDeleteDialog()" nzDanger>Delete</button>
    <ng-content *ngIf="pointInfoMode === PointInfoMode.CesiumMode" select="[content]"></ng-content>
  </div>
</form>
