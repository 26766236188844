
     <!-- <section>
          <label for="Landmark">Marker Display: &nbsp;</label>
          <input type="radio" [(ngModel)]="showIconUrl" [value]="false"> Red Marker &nbsp;&nbsp;
          <input type="radio" [(ngModel)]="showIconUrl" [value]="true"> Preview Icon &nbsp;&nbsp;
     </section> -->

     <ul nz-menu nzMode="horizontal">
          <ng-container *ngFor="let tab of tabInfo.tabList">
               <li *ngIf="tab.name === MapTab.Stage; else otherTab" 
                    nz-submenu [nzTitle]="tab.name" (click)="changeTab(MapTab.Stage)">
                    <ul>
                       <li nz-menu-item [nzSelected]="!tabInfo?.activeStageType && tabInfo.currentTab === MapTab.Stage"
                         (click)="changeTab(MapTab.Stage)">All</li>
                       <li *ngFor="let nav of StageNavList" nz-menu-item 
                         (click)="changeTab(MapTab.Stage, nav)" [nzSelected]="tabInfo.activeStageType === nav" >{{nav}}</li>
                    </ul>
               </li>
               <ng-template #otherTab>
                    <li nz-menu-item [nzSelected]="tab.name === tabInfo.currentTab" (click)="changeTab(tab.name)">
                         {{ tab.name }}
                    </li>
               </ng-template>
          </ng-container>
          <button nz-button nzType="primary" (click)="getData(false)" class="load-btn">
               Load nearby ({{data?.length}}/{{totalCount}})
           </button>
     </ul>

     <div class="map-wrapper">
          <input nz-input type="text" class="auto-complete-field" nzSize="large" id="autoCompleteField" placeholder="Search Place">
          <div id="MapEl" class="map"></div>
          <nz-button-group *ngIf="tabInfo?.currentTab === MapTab.Stage" class="active-btn-group">
               <button *ngFor="let item of activeStateList; let i = index" 
                    [ngClass]="{'active-mit-nz-button': item.active}" class="mit-nz-button" nz-button nzSize="large" 
                    (click)="updateActiveAndGetData(i)"
                    >{{item.label}}</button>
          </nz-button-group>

          <button [hidden]="!currentStageGroup?.length || tabInfo.currentTab !== MapTab.Stage" 
               nz-button [nzType]="'primary'" class="group-btn" (click)="isVisible = true">
               Watchlist({{currentStageGroup.length}})
          </button>
          <nz-modal [(nzVisible)]="isVisible" nzTitle="Watchlist" 
                    [nzFooter]="null" [nzWidth]="600" (nzOnCancel)="isVisible = false">
               <app-add-stage-group *nzModalContent (closeDialog)="isVisible = false"
                    ></app-add-stage-group>
          </nz-modal>
     </div>

     <app-mit-off-canvas>
          <div class="map-detail-wrapper" [ngSwitch]="canvasMode" content>
               <app-map-grid *ngSwitchCase="'grid'"  
               [currentTab]="tabInfo.currentTab"   
               (onOpenDetailPanel)="openDetailPanel($event)"
          >    
          <div stageTabType *ngIf="tabInfo.currentTab === MapTab.Stage" class="tab-info">
               {{tabInfo?.activeStageType || 'All'}}({{currentActiveSateLable}})
          </div>
               <nz-input-group search nzSearch [nzAddOnAfter]="suffixButton">
                    <input type="text" nz-input placeholder="Search by ID" [(ngModel)]="searchID"/>
               </nz-input-group>
               <ng-template #suffixButton>
                    <button nz-button nzType="primary" nzSize="large" nzSearch [disabled]="!searchID" (click)="updateActiveAndGetData(0, {Id: searchID})">Search</button>
               </ng-template>
          </app-map-grid>
          <app-marker-item *ngSwitchCase="'point'" 
               [dataSource]="dataSource" [tabInfo]="tabInfo"
               [currentFocusOnInfo]="currentFocusOnInfo"
          ></app-marker-item>
          </div>

     </app-mit-off-canvas>




