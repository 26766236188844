<div class="upload-wrapper">
  <input
    type="file"
    class="form-control mit-uploader"
    [accept]="accept|| '*'"
    [multiple]="multiple"
    (change)="fileChange($event)"
    [disabled]="formCtrl?.disabled"
    (cancel)="cancel()"
    >
  <!-- <div *ngIf="formCtrl?.value" class="remove-icon ani" nz-icon nzType="close" nzTheme="outline" (click)="cancel()"></div> -->

  <input type="hidden" class="form-control" placeholder="file">

  <div *ngIf="showError" class="error-msg">
    Please upload files
  </div>
</div>


<ng-container *ngIf="previewUrl?.length>0">
  <ng-contaniner *ngIf="showPreviewImage; else showLink">
      <img
      *ngFor="let item of previewUrl"
      nz-image
      [nzSrc]="item.url"
      [id]="item.guid"
      [nzFallback]="fallback" 
      alt=""
      class="preview-image"
    />
  </ng-contaniner>
  <ng-template #showLink>
    <a *ngFor="let item of previewUrl" style="color: #008dff;" target="_blank" [href]="item">
      <span nz-icon nzType="paper-clip" nzTheme="outline" class="clip-icon"></span>
      {{item?.name || item}}
    </a>
  </ng-template>

</ng-container>

