export class MarkerDto {
    googlePlaceId?: string;
    name?: string;
    latitude: number;
    longitude: number;
    mmitaRewardId: string;
    campaignWavePortalId: string
    address: string // for portal marker

    id?: string;
    constructor(data, aMapMode?) {
      if(aMapMode){
        this.googlePlaceId = data.id? data.id : data.googlePlaceId;
        this.name = data.name;
        this.latitude =  data.latitude ? data.latitude : data.location ? data.location.lat : 0;
        this.longitude = data.longitude ? data.longitude : data.location ? data.location.lng : 0;
      } else {
        this.googlePlaceId = data.place_id ? data.place_id : data.googlePlaceId;
        this.name = data.name;
        this.latitude =  data.latitude ? data.latitude : data.geometry ? data.geometry.location.lat() : 0;
        this.longitude = data.longitude ? data.longitude : data.geometry ? data.geometry.location.lng() : 0;
      }
      this.mmitaRewardId = data.mmitaRewardId ? data.mmitaRewardId : data.mmitaReward ? data.mmitaReward.id : null;
      this.campaignWavePortalId = data.campaignWavePortalId
      this.address = data?.address || data?.formatted_address
      this.id = data.id
    }
  }

export class MarkerItem {
  googlePlaceId: string;
  name: string;
  latitude: number;
  longitude: number;
  mmitaRewardId: string;
  id:string
}


export class MapPlacesLatLngBounds {
  MinLongitude: number
  MinLatitude: number
  MaxLongitude: number
  MaxLatitude: number
  constructor(info, isCN){
    this.MinLongitude = isCN ? info[0] : info.west
    this.MaxLongitude = isCN ? info[2] :info.east
    this.MinLatitude = isCN ? info[1] :info.south
    this.MaxLatitude = isCN ? info[3] :info.north
  }
}

export class PortalDto {
  latitude:number
  longitude: number
  campaignWaveId: string
  constructor(data) {
    this.latitude = data.latitude
    this.longitude = data.longitude
    this.campaignWaveId = data.campaignWaveId
  }
}
  