import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { guid } from 'src/app/_helpers/utility';
import { Fallback } from 'src/app/common/constants';

@Component({
  selector: 'app-mit-uploader',
  templateUrl: './mit-uploader.component.html',
  styleUrls: ['./mit-uploader.component.css']
})
export class MitUploaderComponent {
  @Input() multiple: boolean
  @Input() showPreviewImage: boolean
  @Input() formCtrl: FormControl
  @Input() accept: string
  @Output() onFileChange = new EventEmitter()
  previewUrl: Array<{url: string, guid: string, name?: string}> = []
  showError = false
  fallback = Fallback
  
  cancel(){
    this.reset()
  }
  ngOnInit(){
    this.setPreview(this.formCtrl?.value)
    this.formCtrl?.statusChanges.subscribe(feedBack=>{
      this.showError = feedBack === 'INVALID'
    })
    this.formCtrl?.valueChanges.subscribe(res=>{
      if(!res){
        return
      }
      this.setPreview(res)
    })
  }
  setPreview(source){
    if(source instanceof Array){
      this.previewUrl = source.map(item=>{
        return {url: item?.url || URL?.createObjectURL(item?.file), guid: item?.guid, name: item?.file?.name}
      })
    } else if (source){
      this.previewUrl = [{url: source, guid: null}]
    }
  }

  reset(){
    this.previewUrl = []
    this.formCtrl.patchValue(null)
  }
  fileChange(event) {
    const files = Array.from(event?.target?.files).map((item: File)=>{
      return {file: item, guid: guid()}
    })
    if (files?.length == 0) {
      this.reset()
    } else {
      this.previewUrl = files.map(item=>{
        return {url: URL.createObjectURL(item.file), guid: item.guid, name: item?.file?.name}
      })
      this.formCtrl?.patchValue(files)
      this.onFileChange.emit(files)
    }
  }

}